@import './../../../../styles/config.scss';

.container {
    padding-top: 100px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: column;


    }

    .banner {
        background-color: $color-primary;
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
            color: #FFF;
        }
    }
}

@media (max-width: 1024px) {
    .banner {
        h2 {
            font-size: 20px;
        }
    }
}