@import './config.scss';

html {
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: $color-primary;
    }
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #ddd;
}

.linePivot {
    .ms-Pivot {
        overflow-y: hidden;
        overflow-x: auto;
        height: 45px;
        border-bottom: 1px solid #ddd;

        .ms-Pivot-link {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;

            &.is-selected .ms-Pivot-text {
                font-weight: 600;
            }
        }
    }

    .ms-Button {
        margin-bottom: -1px;
    }
}

button,
.button {
    outline: 0;
    border: 0px;
    background-color: $color-primary;
    color: $color-white;
    padding: 10px 25px;
    border-radius: 10px;

    font-family: $font-text;
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    text-decoration: none;

    &:hover {
        color: $color-white;
        background-color: $color-primary-dark;
        cursor: pointer;
    }
}

input[type="text"],
textarea {
    outline: 0;
    border: 0px;
    font-family: $font-text;
    font-size: 14px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: $box-shadow;
}