@import './../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px;
    gap: 120px;
    z-index: 1;
    position: relative;

    .pin {
        .icon {
            color: $color-primary;
        }
    }
    
    .divider {
        background-color: #bbb;
        height: 1px;
        width: 100%;
    }

    .wrapper {
        width: 100%;
        background-color: $color-white;
        box-shadow: $box-shadow;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
    }

    .header {
        gap: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .indicator {
            width: 50px;
            height: 5px;
            border-radius: 3px;
            background-color: $color-primary;
        }
    }

    .leftSection {
        width: 40%;
        max-width: 500px;
    }

    .rightSection {
        flex: 1;
        padding: 60px;

        .content {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .email {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            align-content: center;
        }

        .item {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;

        .wrapper {
            flex-direction: column;
            align-items: start;

            .leftSection {
                height: 400px;
                width: 100%;
                max-width: unset;
            }
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px;
        gap: 40px;

        .wrapper {
            .rightSection {
                padding: 20px;
            }
        }
    }
}