@import './../../styles/config.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 120px;
    gap: 40px;
    
    .divider {
        background-color: #bbb;
        height: 1px;
        width: 100%;
    }

    .topSection {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: space-between;
        width: 100%;

        .header {
            margin-bottom: 20px;
            gap: 10px;
            display: flex;
            flex-direction: column;
    
            .indicator {
                width: 30px;
                height: 5px;
                border-radius: 3px;
                background-color: $color-primary;
            }
        }

        .logo {
            max-width: 200px;

            img {
                width: 100%;
            }
        }

        .about {
            max-width: 400px;

            .content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .icon {
                        position: relative;
                        top: 6px;
                    }
                }
            }
        }

        .menu {
            .menuItems {
                display: flex;
                flex-direction: row;
                gap: 40px;

                .link {
                    display: block;
                    text-decoration: none;
                    color: $color-black;
                    margin-bottom: 10px;

                    &:hover {
                        color: $color-primary-dark;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;
        flex-direction: column;

        .topSection {
            flex-direction: column;
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px;
        gap: 40px;
    }
}