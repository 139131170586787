@import './../../../../styles/config.scss';

.container {
    min-height: calc(100vh - 100px);
    padding: 140px 40px 20px 40px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.headerContainer {
    display: flex;
    flex-direction: column;

    .divider {
        background-color: #E48826;
        margin-top: 3px;
        height: 5px;
        width: 50px;
        border-radius: 3px;
    }
}

.tags {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .item {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        background-color: #eee;
        border-radius: 10px;
        padding: 4px 12px;
    }
}

.newsImage {
    width: 400px;
    border-radius: 20px;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.content {
    font-family: 'montserrat', 'quicksand', 'segoe ui', 'open sans', system-ui;
    font-size: 16px;
}

@media (max-width: 1024px) {
    .container {

        .content {
            .buttonContainer {
                flex-direction: column;
            }

        }
    }
}