@import './../../styles/config.scss';

.group {
    .title {
        padding: 8px 20px;
        background-color: $color-light;
        color: $color-primary-dark;
        font-weight: 600;
    }

    .contact {
        list-style-type: none;

        .profileImage {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            object-fit: cover;
        }

        .link {
            display: block;
            padding: 15px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 0px;
        }

        &:hover {
            cursor: pointer;
            background-color: #f8f8f8;
        }

        .name, .phone {
            line-height: 22px;
        }

        .name {
            font-weight: 600;
            font-size: 14px;
        }

        .phone {
            font-size: 12px;
        }
    }
}

.iconContainer {
    z-index: 999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 8px 10px 8px 10px;
    border-radius: 50%;
    background-color: green;
    color: white;
    box-shadow: $box-shadow;

    &:hover {
        cursor: pointer;
        background-color: rgb(1, 93, 1);
    }

    .icon {
        color: white;
        position: relative;
        top: -1px;
        left: 1px;
    }

}

.listContainer {
    display: flex;
    flex-direction: column;
    z-index: 999;
    position: fixed;
    bottom: 85px;
    right: 20px;
    width: calc(100vw - 40px);
    max-width: 300px;
    background: white;
    border-radius: 10px;
    box-shadow: $box-shadow;
    align-self: flex-end;

    .header {
        background-color: green;
        padding: 10px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        color: white;

        .closeIcon {
            padding: 5px 8px;
            border-radius: 50%;

            &:hover {
                cursor: pointer;
                background-color: $color-light;
                color: $color-black;
            }
        }
    }

    .lists {
        padding: 0px;
        margin: 0px;

        .item {
            
        }
    }
}