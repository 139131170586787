@import './../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px;
    gap: 40px;

    .leftSection {
        flex-grow: 1;
    }

    .rightSection {
        background-image: url('./../../../../assets/images/our-services.webp');
        background-position: center center;
        background-size: cover;
        flex-grow: 0;
        flex-shrink: 0;
        width: 350px;
        height: 500px;
        border-radius: 20px;
    }

    .header {
        gap: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .indicator {
            width: 50px;
            height: 5px;
            border-radius: 3px;
            background-color: $color-primary;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        gap: 40px;
        flex-wrap: wrap;
    }

    .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: calc(33.3% - 30px);

        .image {
            display: flex;
            width: 40px;
            height: 40px;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: 10px;
            padding: 10px;

            img {
                object-fit: contain;
                width: 40px;
            }
        }

        .divider {
            height: 1px;
            width: 100%;
            background-color: #bbb;
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;
        flex-direction: column;

        .leftSection,
        .rightSection {
            width: 100%;
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px;
        gap: 40px;

        .item {
            width: calc(50% - 20px);
        }
    }
}