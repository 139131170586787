@import './../../../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;

    .content {
        .list {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;
        }

        .divider {
            height: 1px;
            width: 100%;
            background-color: #bbb;
        }

        .item {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;

            .image {
                display: flex;
                width: 40px;
                height: 40px;
                flex-grow: 0;
                flex-shrink: 0;
                border-radius: 10px;
                padding: 10px;

                img {
                    object-fit: contain;
                    width: 40px;
                }
            }
        }
    }

    .banner {
        background-color: $color-primary;
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
            color: #FFF;
        }
    }

}



@media (max-width: 1024px) {
    .container {
        .banner {
            h2 {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 720px) {
    .container {
        .content {
            .list {
                padding: 20px 20px 0px;
                flex-direction: column;
            }
        }
    }
}