@import './../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px;
    gap: 80px;

    .rightSection {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .leftSection {
        width: 50%;

        .image {
            border-radius: 20px;
            width: 100%;
            height: 500px;
            background-image: url('./../../../../assets/images/request-quote.webp');
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .header {
        gap: 10px;
        display: flex;
        flex-direction: column;

        .indicator {
            width: 50px;
            height: 5px;
            border-radius: 3px;
            background-color: $color-primary;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;
        flex-direction: column-reverse;

        .leftSection,
        .rightSection {
            width: 100%;
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px;
        gap: 10px;

        .leftSection {
            padding-bottom: 10px;
        }

        .rightSection {
            padding-top: 10px;
        }
    }
}