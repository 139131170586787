@import './../../styles/config.scss';

.secondary,
.paragraph {
    font-family: 'montserrat', 'quicksand', 'segoe ui', 'open sans', system-ui;
}

.paragraph {
    font-size: 14px;
    line-height: 28px;
    white-space: pre-wrap;
}

.secondary {
    font-size: 20px;
    line-height: 32px;
    white-space: pre-wrap;
}


.success {
    color: $color-teal-dark;
}

.error {
    color: $color-red-dark;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'quicksand', 'montserrat', 'segoe ui', 'open sans', system-ui;
    font-weight: 600;
}

h1, .h1 {
    font-size: 64px;
}

h2, .h2 {
    font-size: 40px;
}

h3, .h3 {
    font-size: 36px;
}

h4, .h4 {
    font-size: 32px;
}

h5, .h5 {
    font-size: 24px;
}

h6, .h6 {
    font-size: 20px;
}

.paragraph,
.secondary,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0px;
    padding: 0px;
}

@media (max-width: $phone-maxwidth) {
    .h1 {
        font-size: 64px;
    }

    .h2 {
        font-size: 40px;
    }

    .h3 {
        font-size: 32px;
    }

    .h4 {
        font-size: 28px;
    }

    .secondary {
        line-height: 28px;
        font-size: 16px;
    }

    .paragraph {
        font-size: 12px;
        line-height: 26px;
    }
}