@import './../../../../../../styles/config.scss';

.container {
    background-color: $color-primary;

    .content {
        gap: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 80px;

        .sideLeft {
            flex: 1;

            display: flex;
            flex-direction: column;

            gap: 20px;

            .btnText {
                color: black;
            }
        }

        .sideRight {
            max-width: 700px;
            flex: 1;

            img {
                width: 100%;
            }
        }


        .text {
            color: $color-white;
        }
    }
}

@media (max-width: 1024px) {
    .container {
        .content {
            gap: 40px;
            padding: 20px;
            height: auto;
            flex-direction: column;
        }
    }
}