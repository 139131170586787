@import './../../../../styles/config.scss';

.container {
    display: flex;
    align-items: center;
    padding: 0 40px 40px 40px;

}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    gap: 40px;

    .subContent {
        gap: 40px;
        display: flex;
        flex-direction: column;

        .headerContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .divider {
                background-color: #E48826;
                height: 5px;
                width: 100px;
                border-radius: 3px;
            }
        }

        .form {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .field {
                flex-grow: 1;
            }

            .button {
                height: 38px;
                margin-top: 28.4px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .container {

        .content {
            .buttonContainer {
                flex-direction: column;
            }

            .image {}
        }
    }
}