@import './../../../../../../styles/config.scss';

.container {
    background-color: #E1DFDD;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 80px;
        padding: 80px;

        .rightSide,
        .leftSide {
            flex: 1;
        }

        .leftSide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .rightSide {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .list {
                padding: 0px;
                margin: 0px;

                .item {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 0px;

                    .text {
                        text-transform: uppercase;
                    }
                    
                    .icon {
                        background-color: $color-primary;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                    }
                }
            }
        }

        img {
            width: 100%;
            border-radius: 40px;
            height: auto;
        }
    }
}

@media (max-width: 1024px) {
    .container {
        .content {
            gap: 40px;
            padding: 40px 20px;
            flex-direction: column;
        }
    }
}