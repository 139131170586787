@import './../../../../styles/config.scss';

.container {
    display: flex;
    align-items: center;
    padding: 0 40px 0 40px;

}

.containerPage {
    padding: 140px 40px 40px !important;
}

.content {
    .subContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

.document {
    background-color: $color-white;
    border: 1px solid #aaa;
    border-radius: 4px;
    width: 100px;
    height: 100px;

    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .filename {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.documentDialog {
    
}