@import './../../../../../../styles/config.scss';

.card {
    position: relative;

    .status {
        width: 125px;
        height: 36px;

        .tag {
            height: fit-content;
            padding: 2px 12px;
            border-radius: 4px;

            &.scheduled {
                background-color: #eee;
            }

            &.inprogress {
                background-color: $color-primary-dark;
                * {color: $color-white;}
            }

            &.completed {
                background-color: $color-success;
                * {color: $color-white;}
            }
        }
    }

    .content {
        min-height: 36px;
        height: fit-content;
        padding: 6px 0px;

        .body {
            padding: 12px;
            border-radius: 4px;
            background-color: #eee;
        }
    }
    
    .line {
        position: absolute;
        width: 2px;
        background-color: #ccc;
        top: 24px;
        left: 132px;
        height: calc(100% + 30px);
        z-index: -1;
    }
    
    .dot {
        width: 16px;
        height: 36px;
    }
}