@import './../../../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px;
    gap: 40px;

    .header {
        margin-bottom: 40px;
        gap: 10px;
        display: flex;
        flex-direction: column;

        .indicator {
            width: 50px;
            height: 5px;
            border-radius: 3px;
            background-color: $color-primary;
        }
    }

    .leftSection {
        max-width: 450px;
        flex-grow: 1;
    }

    .rightSection {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 40px;
        column-gap: 40px;
    }

    .image {
        width: calc(33% - 45px);
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: $box-shadow;
        border-radius: 10px;
        
        img {
            max-width: calc(100% - 10px);
            max-height: 100%;
            object-fit: contain;
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;
        flex-direction: column;
        align-items: start;

        .leftSection,
        .rightSection {
            width: 100%;
        }

        .image {
            width: calc(50% - 40px);
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px 20px;
        gap: 40px;

        .image {
            width: calc(100%);
        }

        .rightSection {
            row-gap: 20px;
            column-gap: 20px;
        }
    }
}