@import './../../../../styles/config.scss';

.container {
    display: flex;
    align-items: center;
    padding: 160px 40px 80px 40px;

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 40px;
    }
}

.header {
    gap: 10px;
    display: flex;
    flex-direction: column;

    .indicator {
        width: 50px;
        height: 5px;
        border-radius: 3px;
        background-color: $color-primary;
    }

}

@media (max-width: 1024px) {}