@import './../../../../styles/config.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px;
    gap: 120px;

    .leftSection {
        width: 50%;
    }

    .rightSection {
        width: 50%;
    }

    .header {
        gap: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .indicator {
            width: 50px;
            height: 5px;
            border-radius: 3px;
            background-color: $color-primary;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .item {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .image {
            display: flex;
            width: 50px;
            height: 50px;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: 10px;
            padding: 10px;
            margin-top: 5px;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .leftSection {}

    .rightSection {}

    .header {}

    .topImage,
    .bottomImage {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin: 0px 0px 0px auto;
        align-items: center;
        justify-content: center;
    }

    .topLeftImage,
    .topRightImage,
    .bottomLeftImage,
    .bottomRightImage {
        background-size: cover;
        background-position: center center;
        width: 100%;
        padding-top: 50%;
    }

    .topImage {
        margin-bottom: 10px;

        .topLeftImage {
            background-image: url('./../../../../assets/images/why-us-top-left.webp');
            border-bottom-left-radius: 100px;
            border-top-right-radius: 100px;
        }

        .topRightImage {
            background-image: url('./../../../../assets/images/why-us-top-right.webp');
            border-top-left-radius: 100px;
            border-bottom-right-radius: 100px;
        }
    }

    .bottomImage {
        .bottomLeftImage {
            background-image: url('./../../../../assets/images/why-us-bottom-left.webp');
            border-top-left-radius: 100px;
            border-bottom-right-radius: 100px;
        }

        .bottomRightImage {
            background-image: url('./../../../../assets/images/why-us-bottom-right.webp');
            border-bottom-left-radius: 100px;
            border-top-right-radius: 100px;
        }
    }
}

@media (max-width: $tablet-maxwidth) {
    .container {
        padding: 80px;
        gap: 80px;
        flex-direction: column;

        .leftSection,
        .rightSection {
            width: 100%;
        }
    }
}

@media (max-width: $phone-maxwidth) {
    .container {
        padding: 40px;
        gap: 40px;

        .topImage {
            .topLeftImage {
                border-bottom-left-radius: 40px;
                border-top-right-radius: 40px;
            }

            .topRightImage {
                border-top-left-radius: 40px;
                border-bottom-right-radius: 40px;
            }
        }

        .bottomImage {
            .bottomLeftImage {
                border-top-left-radius: 40px;
                border-bottom-right-radius: 40px;
            }

            .bottomRightImage {
                border-bottom-left-radius: 40px;
                border-top-right-radius: 40px;
            }
        }
    }
}