@import './../../../../../../styles/config.scss';

.container {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    padding: 120px 80px 20px;
    position: relative;

    .content {
        width: 700px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        z-index: 1;

        .description {
            width: 500px;
        }
    }

    .image {
        position: absolute;
        border-radius: 40px;
        background-size: cover;
        background-image: url('./../../../../../../assets/images/hero-banner.webp');
        background-position: center center;
        left: 700px;
        right: 0px;
        top: 180px;
        bottom: 80px;
        /*position: absolute;
        object-fit: cover;
        top: 160px;
        bottom: 40px;
        width: 100%;
        
        height: 100%;
        background-position: center center;*/
    }
}

@media (max-width: $phone-maxwidth) {
    .container {       
        padding: 20px;
         
        h1 {
            font-size: 40px;
        }

        .content {
            width: 100%;
            max-width: 550px;

            .description {
                width: 100%;
                max-width: 340px;
            }
        }

        .image {
            position: absolute;
            background-size: cover;
            background-image: url('./../../../../../../assets/images/hero-banner.webp');
            background-position: center center;
            border-radius: 0px;
            opacity: 0.3;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
        }
    }
}