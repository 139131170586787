$color-primary: #E48826;
$color-primary-dark: #d68227;

$color-blue-dark: #004578;
$color-blue-light: #DEECF9;

$color-teal-dark: #004940;
$color-teal-light: #C5EBE7;

$color-red-dark: #9F282C;
$color-red-light: #F8DADB;

$color-yellow-dark: #8F6900;
$color-yellow-light: #FFF4D6;

$color-light: #EEEEEE;
$color-black: #000;
$color-white: #fff;
$color-success: #28a745;

$max-screen-width: 1440px;

$font-text: 'quicksand', 'montserrat', 'segoe ui', 'open sans', system-ui;

$box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, .132), 0 0.3px 0.9px 0 rgba(0, 0, 0, .108);

$tablet-maxwidth: 1024px;
$phone-maxwidth: 680px;

.bg-image {
    position: relative;
    background-position: center center;
    background-size: cover;

    &.bg-reachus {
        background-image: url('./../assets/images/reach-us.webp');
    }

    .bg-backdrop {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-color: #000;
        opacity: 0.5;
    }
}

.bg-yellow-light {
    background-color: $color-yellow-light;
}

.bg-teal-light {
    background-color: $color-teal-light;
}

.bg-blue-light {
    background-color: $color-blue-light;
}

.bg-red-light {
    background-color: $color-red-light;
}

.bg-light {
    background-color: $color-light;
}

.color-red-dark {
    color: $color-red-dark;
}

.color-yellow-dark {
    color: $color-yellow-dark;
}

.color-blue-dark {
    color: $color-blue-dark;
}

.color-teal-dark {
    color: $color-teal-dark;
}