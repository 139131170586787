@import './../../../../styles/config.scss';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 160px 40px 80px 40px;
    min-height: calc(100vh - 650px);
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {}