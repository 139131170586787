@font-face {
    font-family: "quicksand";
    font-display: swap;
    src: url('./../assets/fonts/quicksand/Quicksand-Regular.ttf');
}

@font-face {
    font-family: "montserrat";
    font-display: swap;
    src: url('./../assets/fonts/montserrat/Montserrat-Regular.ttf');
}