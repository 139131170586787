@import './../../styles/config.scss';

.menubar {
    position: fixed;
    width: 100vw;
    height: 100px;
    background-color: $color-white;
    box-shadow: 1px 1px 5px 3px #ccc;
    z-index: 999;

    .container {
        padding: 20px;
        max-width: 1440px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 60px;
        }

        .login {
            height: 60px;
            display: flex;
            align-items: center;
        }

        .menuButton {
            display: none;
        }
    }

    .items {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        height: 60px;
        display: flex;
        flex-grow: 1;
        justify-content: center;

        .item {
            float: left;
            height: 100%;

            .link {
                position: relative;
                text-decoration: none;
                display: flex;
                align-items: center;
                padding: 0px 10px;
                margin: 0px 5px;
                height: 100%;
                color: $color-black;
                font-family: $font-text;
                font-size: 16px;

                &:active,
                &:hover {
                    color: $color-black;
                }

                &:hover {
                    font-weight: 600;
                }
            }

            &.active {
                .link {
                    font-weight: 600;

                    .indicator {
                        position: absolute;
                        top: 45px;
                        height: 5px;
                        width: 30px;
                        border-radius: 3px;
                        background-color: $color-primary;
                    }
                }
            }
        }
    }

    .mobileMenu {
        display: none;
    }
}

@media (max-width: 1024px) {
    .menubar {
        .container {
            .items {
                display: none;
            }

            .menuButton {
                display: block;

                button {
                    background-color: transparent;
                    color: $color-black;
                }
            }
        }

        .mobileMenu {
            display: block;
            position: fixed;
            padding: 20px;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background-color: $color-white;
            z-index: 999;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    height: 60px;
                }

                .menuButton button {
                    background-color: transparent;
                    color: $color-black;
                    padding: 20px;
                }
            }

            .items {
                list-style-type: none;
                display: block;

                .item {
                    float: none;

                    .link {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }
        }
    }
}